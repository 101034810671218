import React from 'react';
import Header from '../components/Header'; // Assuming your Header component is in the components folder
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import arshiaImage from '../assets/Arshia.png';
import farzanImage from '../assets/Farzan.png'; // Assuming you have this image

const About = () => {
  return (
    <div>
      <Header /> {/* Including the Header component */}
      <div className="container mx-auto py-8 px-4 md:py-16 md:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 md:mb-8">About Us</h2>
        <p className="text-center text-base md:text-lg mb-8 md:mb-16">Learn more about Arshia Network and the people behind it.</p>

        {/* Who We Are Section */}
        <div className="flex flex-col items-center mb-12 md:mb-16">
          <p className="text-sm md:text-lg max-w-2xl text-center mb-4">
            My name is Arshia Feizmohammady, and I am an engineering student at the University of Toronto with over 5 years of coding experience. I take great pride in every website I create, ensuring that each one is crafted with meticulous attention to detail. My passion for web development drives me to deliver high-quality, custom solutions that truly reflect the unique needs of each client.
          </p>
          <img src={arshiaImage} alt="Arshia Feizmohammady" className="w-20 md:w-32 h-20 md:h-32 rounded-full mb-4" />
          <h3 className="text-lg md:text-2xl font-semibold">Arshia Feizmohammady</h3>
          <p className="text-gray-600">President</p>
        </div>

        <div className="flex flex-col items-center mb-12">
          <p className="text-sm md:text-lg max-w-2xl text-center mb-4">
            My name is Farzan Feizmohammady, and I have over 15 years of experience in the IT industry, holding the prestigious MCSE certification. I am proud to be part of Arshia Network as a Director, where we are a Lenovo Business Pro partner. We assist customers with their IT needs, including maintenance, solutions, and hardware procurement.
          </p>
          <img src={farzanImage} alt="Farzan Feizmohammady" className="w-20 md:w-32 h-20 md:h-32 rounded-full mb-4" />
          <h3 className="text-lg md:text-2xl font-semibold">Farzan Feizmohammady</h3>
          <p className="text-gray-600">Director</p>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-8 md:py-10 w-full">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="mb-6 md:mb-0 md:w-1/3">
              <h2 className="text-lg md:text-2xl font-bold mb-2">Arshia Network</h2>
              <p className="text-sm md:text-base">Email: info@arshianetwork.com</p>
              <p className="text-sm md:text-base">Phone: +1 (514) 715-0010</p>
            </div>

            {/* Navigation Links */}
            <div className="mb-6 md:mb-0 md:w-1/3">
              <h3 className="text-lg font-semibold mb-2">Navigation</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="md:w-1/3">
              <h3 className="text-lg font-semibold mb-2">Services</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-6 md:mt-8 text-center">
            <p className="text-xs md:text-base">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
