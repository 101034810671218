import React from 'react';
import Header from '../components/Header'; // Assuming your Header component is in the components folder
import piggyBankImage from '../assets/piggy-bank.png'; // Assuming the image path is correct
import mobileFirstIcon from '../assets/mobile-first-icon.svg';
import responsiveIcon from '../assets/responsive-icon.svg';
import optimizationIcon from '../assets/optimization-icon.svg';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div>
      <Header /> {/* Including the Header component */}
      
      {/* $0 Down, $150 Per Month Section */}
      <section id="services-start" className="py-12 md:py-20 bg-white text-gray-800">
        <div className="container mx-auto flex flex-wrap items-center">
          {/* Left Side: Piggy Bank Illustration */}
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={piggyBankImage} alt="Piggy Bank Illustration" className="max-w-full h-auto mx-auto" />
          </div>
          
          {/* Right Side: Content */}
          <div className="w-full md:w-1/2 md:pl-10">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-600">$0 Down, $150 Per Month, 12 Month Minimum Contract</h2>
            <p className="mt-4 text-base md:text-lg text-gray-700">
              $0 down for a standard 5-page small business website. If you need more than that, custom pricing is based on the scope of work, additional pages, and time involved.
              You own your domain, content, and profiles. Cancel anytime with no fees or hassle.
            </p>
            <ul className="mt-8 space-y-4">
              <li className="flex items-center">
                <img src={mobileFirstIcon} alt="Hosting Fees Included Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">Hosting Fees Included - Hosting fees are built into the monthly payment.</span>
              </li>
              <li className="flex items-center">
                <img src={responsiveIcon} alt="Unlimited Edits Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">Unlimited Edits - Change anything you want, anytime, with same-day updates.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="24/7 Customer Service Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">24/7 Customer Service - Reach out anytime, no automated responses.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="Web Design & Development Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">Web Design & Development - Includes 40+ hours of design and development.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="100 Google Page Speed Score Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">100 Google Page Speed Score - We help boost your search ranking.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="Google Analytics Icon" className="w-6 h-6 mr-4" />
                <span className="text-base">Google Analytics - Free setup to monitor traffic sources.</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Our Packages Section */}
      <section id="packages" className="py-12 md:py-20 bg-gray-100 text-gray-800">
        <div className="container mx-auto text-center px-6">
          <h2 className="text-3xl md:text-4xl font-bold mb-10">OUR PACKAGES</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

            {/* Package 1 */}
            <div className="bg-white rounded-lg shadow-lg border-2 border-green-500">
              <div className="bg-green-100 text-green-600 py-6">
                <h3 className="text-xl font-bold">STANDARD $0 Down</h3>
                <p className="text-3xl font-bold my-4">$150<span className="text-lg">/MO</span></p>
                <p className="text-lg">5 Pages</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left">
                  <li>Unlimited Edits</li>
                  <li>Includes Hosting</li>
                  <li>24/7 Customer Service</li>
                  <li>Lifetime Updates</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6">
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US
                </Link>
              </div>
            </div>

            {/* Package 2 */}
            <div className="bg-white rounded-lg shadow-lg border-2 border-blue-500">
              <div className="bg-blue-100 text-blue-600 py-6">
                <h3 className="text-xl font-bold">STANDARD</h3>
                <p className="text-3xl font-bold my-4">$1000<span className="text-lg"> + $50/MO</span></p>
                <p className="text-lg">5 Pages</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left">
                  <li>Custom Designed</li>
                  <li>Includes Hosting</li>
                  <li>24/7 Customer Service</li>
                  <li>Lifetime Updates</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6">
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US
                </Link>
              </div>
            </div>

            {/* Logos & Graphic Design */}
            <div className="bg-white rounded-lg shadow-lg border-2 border-gray-500">
              <div className="bg-gray-100 text-gray-600 py-6">
                <h3 className="text-xl font-bold">LOGOS & GRAPHIC DESIGN</h3>
                <p className="text-3xl font-bold my-4">$0</p>
                <p className="text-lg">INCLUDED!</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left">
                  <li>2 Revisions</li>
                  <li>All File Formats</li>
                  <li>Ready to Use</li>
                  <li>FREE w/ Subscription</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6">
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US
                </Link>
              </div>
            </div>

            {/* IT Support Package */}
            <div className="bg-white rounded-lg shadow-lg border-2 border-yellow-500">
              <div className="bg-yellow-100 text-yellow-600 py-6">
                <h3 className="text-xl font-bold">IT SUPPORT</h3>
                <p className="text-3xl font-bold my-4">Contact for a Quote</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left">
                  <li>24/7 Technical Support</li>
                  <li>System Maintenance</li>
                  <li>Software Installation</li>
                  <li>Network Management</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6">
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US
                </Link>
              </div>
            </div>

            {/* Hardware Procurement Package */}
            <div className="bg-white rounded-lg shadow-lg border-2 border-red-500">
              <div className="bg-red-100 text-red-600 py-6">
                <h3 className="text-xl font-bold">HARDWARE PROCUREMENT</h3>
                <p className="text-3xl font-bold my-4">Contact for a Quote</p>
              </div>
              <div className="px-6 py-4">
                <ul className="list-disc list-inside text-left">
                  <li>Hardware Sourcing</li>
                  <li>Vendor Negotiation</li>
                  <li>Installation Services</li>
                  <li>Onsite Setup</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6">
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-10 w-full">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-xl md:text-2xl font-bold mb-2">Arshia Network</h2>
              <p className="text-sm md:text-base">Email: info@arshianetwork.com</p>
              <p className="text-sm md:text-base">Phone: +1 (514) 715-0010</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Navigation</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Services</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm md:text-base">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Services;
