import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import arshiaImage from '../assets/Arshia.png';
import farzanImage from '../assets/Farzan.png'; // Assuming you have this image
import logo from '../assets/logo.svg'; // Make sure this path is correct

const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:info@arshianetwork.com?subject=Contact%20Form%20Submission%20from%20${form.name}&body=Name:%20${form.name}%0AEmail:%20${form.email}%0AMessage:%20${form.message}`;
    
    window.location.href = mailtoLink;
  };

  return (
    <div>
      {/* Header */}
      <header className="bg-white text-blue-800 py-4">
        <div className="container mx-auto flex justify-between items-center px-4">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="w-10 h-10 mr-2" />
            <span className="font-bold text-xl">ArshiaNetwork</span>
          </Link>
          <nav className="hidden md:flex space-x-4">
            <Link to="/" className="hover:text-gray-500">Home</Link>
            <a href="/#who-we-are" className="hover:text-gray-500">About</a>
            <a href="/#services-start" className="hover:text-gray-500">Services</a>
            <a href="/#portfolio" className="hover:text-gray-500">Portfolio</a>
            <Link to="/contact" className="hover:text-gray-500">Contact</Link>
          </nav>
        </div>
      </header>

      {/* Contact Section */}
      <div className="container mx-auto py-12 px-4 bg-gradient-to-r from-blue-700 to-blue-500 text-white">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">DROP US A LINE</h1>
        <div className="flex flex-wrap justify-center items-center">

          {/* Form */}
          <div className="w-full md:w-1/2 mb-8">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={form.message}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="4"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          {/* Contact Information for Arshia and Farzan */}
          <div className="w-full md:w-1/2 md:pl-10">
            <div className="flex flex-col md:flex-row items-center md:items-start">
              {/* Arshia's Info */}
              <div className="flex flex-col items-center md:items-start mb-8 md:mb-0 md:mr-8">
                <img src={arshiaImage} alt="Arshia Feizmohammady" className="w-24 h-24 md:w-32 md:h-32 rounded-full mb-4" />
                <h2 className="text-xl md:text-2xl font-bold mb-4">Contact Information</h2>
                <p className="mb-2">
                  <strong>Email:</strong> 
                  <br />info@arshianetwork.com
                </p>
                <p className="mb-2">
                  <strong>LinkedIn:</strong> 
                  <br /><a href="https://www.linkedin.com/in/arshiafeiz/?originalSubdomain=ca" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">Arshia Feizmohammady</a>
                </p>
                <p className="mb-2">
                  <strong>Phone:</strong> 
                  <br />+1 (514) 715-0010
                </p>
              </div>

              {/* Farzan's Info */}
              <div className="flex flex-col items-center md:items-start">
                <img src={farzanImage} alt="Farzan Feizmohammady" className="w-24 h-24 md:w-32 md:h-32 rounded-full mb-4" />
                <h2 className="text-xl md:text-2xl font-bold mb-4">Contact Information</h2>
                <p className="mb-2">
                  <strong>Email:</strong> 
                  <br />info@arshianetwork.com
                </p>
                <p className="mb-2">
                  <strong>LinkedIn:</strong> 
                  <br /><a href="https://www.linkedin.com/in/farzan-feiz/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">Farzan Feizmohammady</a>
                </p>
                <p className="mb-2">
                  <strong>Phone:</strong> 
                  <br />+1 (514) 715-0010
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-10 w-full">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-xl md:text-2xl font-bold mb-2">Arshia Network</h2>
              <p className="text-sm md:text-base">Email: info@arshianetwork.com</p>
              <p className="text-sm md:text-base">Phone: +1 (514) 715-0010</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Navigation</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Services</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm md:text-base">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
