import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import mobileFirstIcon from '../assets/mobile-first-icon.svg';
import responsiveIcon from '../assets/responsive-icon.svg';
import optimizationIcon from '../assets/optimization-icon.svg';
import piggyBankImage from '../assets/piggy-bank.png';
import arshiaProjectImage from '../assets/arshiafeizmohammady.png';
import arshiaImage from '../assets/Arshia.png';
import farzanImage from '../assets/Farzan.png';

const Home = () => {
  const projects = [
    {
      title: "Arshia Feizmohammady",
      location: "Toronto, Canada",
      description: "A 3D Developer Portfolio showcasing the personal and professional achievements of Arshia Feizmohammady.",
      image: arshiaProjectImage,
      link: "https://arshiafeizmohammady.com",
      linkText: "VISIT WEBSITE"
    }
  ];

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Small Business Web Design Section (Hero) */}
      <section id="hero" className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-12 md:py-24">
        <div className="container mx-auto text-center px-6 md:px-12">
          <h1 className="text-3xl md:text-5xl font-extrabold mb-6 md:mb-10">Small Business Web Design + IT Support</h1>
          <p className="text-base md:text-lg mb-6 md:mb-10 max-w-3xl mx-auto">
            No page builders or WordPress - We offer 100% hand-coded websites with superior results starting at $150/mo.
          </p>
          <Link to="/contact" className="bg-white text-blue-600 hover:bg-gray-200 font-bold py-3 px-6 rounded transition duration-300">
            GET IN TOUCH!
          </Link>
        </div>
      </section>

      {/* What We Do Section */}
      <section id="what-we-do" className="py-12 md:py-20 bg-white text-gray-800">
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12">WHAT WE DO</h2>
          <div className="flex flex-wrap justify-center gap-8">
            <div className="flex flex-col items-center max-w-xs md:max-w-sm">
              <img src={mobileFirstIcon} alt="Mobile-First Design Icon" className="w-16 md:w-20 h-16 md:h-20 mb-6" />
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Mobile-First Design</h3>
              <p className="text-base md:text-lg text-gray-600">
                We start building your site for mobile devices first, then we add on to make it tablet and desktop ready.
              </p>
            </div>
            <div className="flex flex-col items-center max-w-xs md:max-w-sm">
              <img src={responsiveIcon} alt="Fully Responsive Icon" className="w-16 md:w-20 h-16 md:h-20 mb-6" />
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Fully Responsive</h3>
              <p className="text-base md:text-lg text-gray-600">
                Your website will fit all screen sizes—mobile, tablet, and desktop—ensuring new clients can access your site from anywhere.
              </p>
            </div>
            <div className="flex flex-col items-center max-w-xs md:max-w-sm">
              <img src={optimizationIcon} alt="Optimization Icon" className="w-16 md:w-20 h-16 md:h-20 mb-6" />
              <h3 className="text-xl md:text-2xl font-semibold mb-4">Optimization</h3>
              <p className="text-base md:text-lg text-gray-600">
                We optimize your site for mobile devices to ensure the best performance in search engines.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="services-start" className="py-12 md:py-20 bg-white text-gray-800">
        <div className="container mx-auto flex flex-wrap items-center">
          {/* Left Side: Piggy Bank Illustration */}
          <div className="w-full md:w-1/2 mb-10 md:mb-0">
            <img src={piggyBankImage} alt="Piggy Bank Illustration" className="max-w-full h-auto mx-auto" />
          </div>

          {/* Right Side: Content */}
          <div className="w-full md:w-1/2 md:pl-10">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4">$0 Down, $150 Per Month, 12 Month Minimum Contract</h2>
            <p className="text-base md:text-lg text-gray-700 mb-6">
              $0 down for a standard 5-page small business website. Additional pages and custom features are priced based on the scope of work.
            </p>
            <ul className="space-y-4 md:space-y-6">
              <li className="flex items-center">
                <img src={mobileFirstIcon} alt="Hosting Fees Included Icon" className="w-6 h-6 mr-4" />
                <span className="text-base md:text-lg">Hosting fees are built into the monthly payment.</span>
              </li>
              <li className="flex items-center">
                <img src={responsiveIcon} alt="Unlimited Edits Icon" className="w-6 h-6 mr-4" />
                <span className="text-base md:text-lg">Unlimited edits—change anything you want anytime.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="24/7 Customer Service Icon" className="w-6 h-6 mr-4" />
                <span className="text-base md:text-lg">24/7 customer service—direct call anytime, no automated responses.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="Web Design & Development Icon" className="w-6 h-6 mr-4" />
                <span className="text-base md:text-lg">Includes over 40 hours of design, development, and testing.</span>
              </li>
              <li className="flex items-center">
                <img src={optimizationIcon} alt="Google Analytics Icon" className="w-6 h-6 mr-4" />
                <span className="text-base md:text-lg">We install Google Analytics for free to monitor traffic and sources.</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section id="portfolio" className="py-12 md:py-20 bg-white text-gray-800">
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12">OUR PORTFOLIO</h2>
          <div className="flex flex-wrap justify-center gap-6 md:gap-8">
            {projects.map((project, index) => (
              <div key={index} className="max-w-xs md:max-w-sm rounded overflow-hidden shadow-lg">
                <img className="w-full" src={project.image} alt={project.title} />
                <div className="px-6 py-4">
                  <h3 className="font-bold text-lg md:text-xl mb-2">{project.title}</h3>
                  <p className="text-gray-700 text-base">{project.location}</p>
                  <p className="text-gray-600 text-sm">{project.description}</p>
                </div>
                <div className="px-6 py-4">
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {project.linkText}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Who We Are Section */}
      <section id="who-we-are" className="py-12 md:py-20 bg-white text-gray-800">
        <div className="container mx-auto px-6 md:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center justify-items-center">
            {/* Arshia's Section */}
            <div className="flex flex-col items-center">
              <img src={arshiaImage} alt="Arshia Feizmohammady" className="w-32 h-32 md:w-40 md:h-40 rounded-full mb-4" />
              <h3 className="text-xl md:text-2xl font-semibold">Arshia Feizmohammady</h3>
              <p className="text-gray-600">President</p>
            </div>

            {/* Farzan's Section */}
            <div className="flex flex-col items-center">
              <img src={farzanImage} alt="Farzan Feizmohammady" className="w-32 h-32 md:w-40 md:h-40 rounded-full mb-4" />
              <h3 className="text-xl md:text-2xl font-semibold">Farzan Feizmohammady</h3>
              <p className="text-gray-600">Director</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-12 w-full">
        <div className="container mx-auto px-6 md:px-12">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-xl md:text-2xl font-bold mb-2">Arshia Network</h2>
              <p className="text-sm md:text-base">Email: info@arshianetwork.com</p>
              <p className="text-sm md:text-base">Phone: +1 (514) 715-0010</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Navigation</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Services</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm md:text-base">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
