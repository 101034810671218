import React from 'react';
import Header from '../components/Header'; // Assuming your Header component is in the components folder
import { Link } from 'react-router-dom';

// Import the image or screenshot of your project
import arshiaProjectImage from '../assets/arshiafeizmohammady.png'; // Update this with the correct image path

const Portfolio = () => {
  // Portfolio projects array
  const projects = [
    {
      title: "Arshia Feizmohammady",
      location: "Toronto, Canada",
      description: "A 3D Developer Portfolio showcasing the personal and professional achievements of Arshia Feizmohammady.",
      image: arshiaProjectImage,
      link: "https://arshiafeizmohammady.com",
      linkText: "VISIT WEBSITE"
    }
  ];

  return (
    <div>
      <Header /> {/* Including the Header component */}
      
      {/* Portfolio Section */}
      <section className="py-16 bg-white text-gray-800">
        <div className="container mx-auto text-center px-4 md:px-12">
          <h2 className="text-4xl font-bold mb-12">OUR PORTFOLIO</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project, index) => (
              <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg mx-auto">
                <img className="w-full object-cover h-48" src={project.image} alt={`Project - ${project.title}`} /> {/* Updated alt attribute */}
                <div className="px-6 py-4">
                  <h3 className="font-bold text-xl mb-2">{project.title}</h3>
                  <p className="text-gray-700 text-base">{project.location}</p>
                  <p className="text-gray-600 text-sm mt-2">{project.description}</p>
                </div>
                <div className="px-6 pt-4 pb-6">
                  <a 
                    href={project.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    {project.linkText}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gradient-to-r from-blue-700 to-blue-500 text-white py-10 w-full">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            {/* Logo and Contact Info */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-xl md:text-2xl font-bold mb-2">Arshia Network</h2>
              <p className="text-sm md:text-base">Email: info@arshianetwork.com</p>
              <p className="text-sm md:text-base">Phone: +1 (514) 715-0010</p>
            </div>

            {/* Navigation Links */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Navigation</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/services" className="hover:underline">Services</Link></li>
                <li><Link to="/portfolio" className="hover:underline">Portfolio</Link></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>

            {/* Services Links */}
            <div className="w-full md:w-1/3">
              <h3 className="text-lg md:text-xl font-semibold mb-2">Services</h3>
              <ul className="text-sm md:text-base">
                <li><Link to="/services" className="hover:underline">Web Design</Link></li>
                <li><Link to="/services" className="hover:underline">Website Maintenance</Link></li>
                <li><Link to="/services" className="hover:underline">SEO Services</Link></li>
                <li><Link to="/services" className="hover:underline">Content Creation</Link></li>
                <li><Link to="/services" className="hover:underline">Logo Design</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm md:text-base">© 2024 Arshia Network. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Portfolio;
